import { useTranslation } from '@loveholidays/phrasebook';
import React, { forwardRef } from 'react';

import { OfferFlights } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import {
  FlightDetails,
  FlightDetailsProps,
  FlightDetailsVariants,
} from '@Components/FlightDetails/FlightDetails';
import { useProductClickTracking } from '@Tracking';

export interface FlightsProps {
  alternativeFlightsUrl?: string;
  onAlternativeFlightsClick?: () => void;
  isOpenJaw: OfferFlights['isOpenJaw'];
  outbound: FlightDetailsProps['leg'];
  inbound: FlightDetailsProps['leg'];
  hotelName: string;
  hotelMasterId: string;
  flightDetailsVariant?: FlightDetailsVariants;
}

export const Flights = forwardRef<HTMLDivElement, FlightsProps & ClassNameProps>(
  (
    {
      alternativeFlightsUrl,
      onAlternativeFlightsClick,
      isOpenJaw,
      outbound,
      inbound,
      className,
      id,
      hotelName,
      hotelMasterId,
      flightDetailsVariant = 'small',
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const alternativeFlightsTracking = useProductClickTracking();

    return (
      <div
        ref={ref}
        id={id}
        className={className}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FlightDetails
          leg={outbound}
          icon="Markers/FlightsOut"
          variant={flightDetailsVariant}
          expandStops={false}
          direction="outbound"
          sx={{
            paddingBottom: 'xs',
          }}
        />
        <FlightDetails
          sx={{
            ...(flightDetailsVariant !== 'compact' && {
              paddingY: 'xs',
              borderColor: 'flightCardBorder',
              borderTopWidth: 1,
              borderStyle: 'solid',
            }),
          }}
          leg={inbound}
          icon="Markers/FlightsIn"
          isOpenJaw={isOpenJaw}
          variant={flightDetailsVariant}
          direction="inbound"
          expandStops={false}
        />
        {alternativeFlightsUrl && (
          <Button
            variant="Secondary"
            size="36"
            href={alternativeFlightsUrl}
            data-id="alt-flights-tooltip-button"
            asAnchor={false}
            onClick={() => {
              alternativeFlightsTracking([
                {
                  name: hotelName,
                  masterId: hotelMasterId,
                  ref: 'srp-to-alternative-flights',
                },
              ]);
              onAlternativeFlightsClick?.();
            }}
          >
            {t('viewAlternativeFlights')}
          </Button>
        )}
      </div>
    );
  },
);
